<mat-toolbar mat-dialog-title>{{ title }}</mat-toolbar>

<mat-dialog-content style="padding: 2em 1em">
  <form [formGroup]="form">
    <div>
      Define a supply chain for a single sales-product or a product group that share the same input-materials.
    </div>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input autotrim formControlName="name" matInput>
      <mat-hint>Name of product, product group or article number(s)</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>HS-Code</mat-label>
      <input autotrim formControlName="hsCode" matInput placeholder="e.g. 44 01">
      <mat-hint>Harmonized System Code / HS-Code</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Notes</mat-label>
      <textarea autotrim formControlName="notes" matInput placeholder="e.g. Article Numbers"></textarea>
      <mat-hint>Internal Notes</mat-hint>
    </mat-form-field>
  </form>
</mat-dialog-content>

<dialog-form-actions>
  <button mat-button mat-dialog-close>cancel</button>
  <button [disabled]="form.invalid" [mat-dialog-close]="form.value" color="primary" mat-raised-button>
    <mat-icon>save</mat-icon>
    {{ action }}
  </button>
</dialog-form-actions>

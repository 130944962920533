import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {Component, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {AutotrimDirective, DialogFormActionsComponent} from '../../common';

@Component({
  selector: 'app-product-edit-dialog',
  standalone: true,
  templateUrl: 'product-detail-dialog.component.html',
  imports: [
    CommonModule, ReactiveFormsModule, MatDialogModule, DialogFormActionsComponent, MatToolbarModule, MatIconModule,
    MatFormFieldModule, MatButtonModule, MatInputModule, AutotrimDirective,
  ]
})
export class ProductEditDialogComponent {
  form = new FormBuilder().nonNullable.group({
    name: ['', Validators.required],
    hsCode: ['', Validators.required],
    notes: ['']
  });
  title = 'Edit Supply Chain';
  action = 'Update';

  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    this.form.patchValue(data.product);
  }
}

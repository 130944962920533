<mat-toolbar mat-dialog-title>
  <div>{{ title }}</div>
  <div style="flex-grow: 1"></div>
  <mat-icon mat-dialog-close>close</mat-icon>
</mat-toolbar>

<mat-dialog-content>
  <div>
    <ul>
      <li *ngFor="let item of outline">
        <a (click)="navigateTo(item.dest)">{{ item.title }}</a>
        <ul>
          <li *ngFor="let child of item.items">
            <a (click)="navigateTo(child.dest)">{{ child.title }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div>
    <div class="controls">
      <button mat-icon-button (click)="zoomOut()">
        <mat-icon>zoom_out</mat-icon>
      </button>
      <button mat-icon-button (click)="zoomIn()">
        <mat-icon>zoom_in</mat-icon>
      </button>
    </div>

    <pdf-viewer
      [src]="src"
      [zoom]="zoom"
      [zoom-scale]="'page-height'"
      [render-text]="false"
      [autoresize]="true"
      [show-borders]="true"
      style="width: 100%; height: 100vh;"
      (after-load-complete)="afterLoadComplete($event)"
    ></pdf-viewer>
  </div>
</mat-dialog-content>

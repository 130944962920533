<mat-toolbar mat-dialog-title>
  Terms-of-use
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="form" style="margin-top: 20px">
    <mat-form-field>
      <mat-label>Login</mat-label>
      <input formControlName="email" matInput>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input autotrim formControlName="name" matInput>
    </mat-form-field>

    <mat-checkbox formControlName="acceptedTerms">
      Accept <a href="https://www.supplycanvas.com/datenschutzerklarung" target="_blank">terms of use</a>
    </mat-checkbox>
  </form>
</mat-dialog-content>

<dialog-form-actions>
  <button (click)="save()" [disabled]="form.invalid || !form.dirty" color="primary" mat-raised-button>
    <mat-icon>check</mat-icon>
    continue
  </button>
</dialog-form-actions>

import {Component, Inject} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogModule} from "@angular/material/dialog";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {Document} from "../../model";

export interface RowData {
  name: string
  hsCode: string
  quantity: number
  unit: RowUnit
}

type RowUnit = 'm' | 'm2' | 'm3' | 'kg' | 'pcs' | 'other';
type RowGroup = {
  name: FormControl<string>
  hsCode: FormControl<string>
  quantity: FormControl<number>
  unit: FormControl<RowUnit>
}

@Component({
  selector: 'app-document-edit-detail-dialog',
  standalone: true,
  templateUrl: 'document-edit-detail-dialog.component.html',
  styleUrl: 'document-edit-detail-dialog.component.scss',
  imports: [
    CommonModule, ReactiveFormsModule, MatDialogModule, MatToolbarModule, MatIconModule,
    MatButtonModule, MatInputModule, MatSelectModule, MatTableModule,
  ]
})
export class DocumentEditDetailDialogComponent {
  rows = new FormBuilder().array<FormGroup<RowGroup>>([]);
  form = new FormBuilder().nonNullable.group({rows: this.rows});
  units: RowUnit[] = ['m', 'm2', 'm3', 'kg', 'pcs', 'other'];
  dataSource = new MatTableDataSource(this.rows.controls);

  constructor(@Inject(MAT_DIALOG_DATA) data: { document: Document }) {
    if (data.document.details) {
      const rows = JSON.parse(data.document.details) as RowData[];
      rows.forEach(({name, hsCode, quantity, unit}) => this.addRow(name, hsCode, quantity, unit));
    } else {
      this.addRow();
    }
  }

  addRow(name: string = '', hsCode: string = '', quantity: number = 0, unit: RowUnit = 'm3') {
    const row = new FormBuilder().nonNullable.group({name: [''], hsCode: [''], quantity: [0], unit: ['m3' as RowUnit]});
    const updateValidators = () => {
      const {name, hsCode, quantity, unit} = row.controls;
      if (!name.value && !hsCode.value && !quantity.value && name.validator) {
        name.clearValidators();
        quantity.clearValidators();
        unit.clearValidators();
      } else if (!name.validator) {
        name.setValidators(Validators.required);
        quantity.setValidators([Validators.required, Validators.min(0.00001)]);
        unit.setValidators(Validators.required);
      }
      name.updateValueAndValidity({emitEvent: false});
      quantity.updateValueAndValidity({emitEvent: false});
      unit.updateValueAndValidity({emitEvent: false});
      this.rows.updateValueAndValidity({emitEvent: false});
    }
    row.valueChanges.subscribe(() => updateValidators());
    row.patchValue({name, hsCode, quantity, unit}, {emitEvent: true});
    this.rows.push(row);
    this.dataSource.data = this.rows.controls;
    updateValidators()
  }

  deleteRow(index: number) {
    this.rows.removeAt(index);
    this.rows.updateValueAndValidity();
    this.dataSource.data = this.rows.controls;
  }

  formData = () => this.form.value.rows
    ?.filter(({name}) => name)
    ?.map(({name, hsCode, quantity, unit}) => ({name, hsCode, quantity, unit}));
}

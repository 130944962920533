import {Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {PDFDocumentProxy, PdfViewerComponent, PdfViewerModule} from "ng2-pdf-viewer";
import {MatButtonModule} from "@angular/material/button";

interface PDFOutline {
  title: string;
  bold: boolean;
  italic: boolean;
  color: Uint8ClampedArray;
  dest: string | Array<any> | null;
  url: string | null;
  unsafeUrl: string | undefined;
  newWindow: boolean | undefined;
  count: number | undefined;
  items: Array<any>
}

@Component({
  selector: 'app-pdf-preview-dialog',
  standalone: true,
  templateUrl: 'pdf-preview-dialog.component.html',
  styleUrls: ['pdf-preview-dialog.component.scss'],
  imports: [
    CommonModule, MatDialogModule, MatToolbarModule, MatIconModule, PdfViewerModule, MatButtonModule,
  ]
})
export class PDFPreviewDialogComponent implements OnDestroy {
  title!: string;
  src!: string;
  outline!: PDFOutline[];
  zoom = 1;
  pdf!: PDFDocumentProxy;
  @ViewChild(PdfViewerComponent)
  private pdfComponent!: PdfViewerComponent;

  constructor(@Inject(MAT_DIALOG_DATA) data: { pdf: Blob, title: string }) {
    this.src = URL.createObjectURL(data.pdf);
    this.title = data.title;
  }

  async afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.pdf.getOutline().then((outline: Array<PDFOutline>) => {
      this.outline = outline;
    });
  }

  ngOnDestroy() {
    URL.revokeObjectURL(this.src);
  }

  async navigateTo(destination: any) {
    await this.pdfComponent.pdfLinkService.goToDestination(destination);
  }

  zoomIn() {
    this.zoom = Math.min(2, this.zoom + 0.1);
  }

  zoomOut() {
    this.zoom = Math.max(0.5, this.zoom - 0.1);
  }
}

import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {AppId, NewsArticle} from '../../model';
import {firstValueFrom, map} from 'rxjs';
import {buildQuery} from '../../util';

@Injectable({providedIn: 'root'})
export class NewsArticleService extends _Service<NewsArticle> {
  constructor() {
    super('api/news-article');
  }

  import = async (id: string) =>
    firstValueFrom(this.http.post(`api/news-article/import-google/${id}`, null));

  count = async (data: { risk?: AppId, locations: AppId[] }[]) =>
    firstValueFrom(this.http
      .post<{ risk?: AppId, locations: AppId[], count: number, date: Date, news: AppId[] }[]>(
        'api/news-article/count', data)
      .pipe(map(all => all.map(entry => ({...entry, locations: entry.locations.sort()})))));

  previewImage = async (url: string) =>
    firstValueFrom(this.http.get('api/news-article/preview-image' + buildQuery({url}), {responseType: 'text'}));
}

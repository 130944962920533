<mat-toolbar mat-dialog-title>
  Select Supplier
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="form"> (keydown.enter)="$event.preventDefault()" autocomplete="off" novalidate>
    <mat-form-field>
      <mat-label>Company / Product</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input [matAutocomplete]="auto" formControlName="product" matInput>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="entry=$event.option.value"
                        [displayWith]="displayEntry">
        <mat-option *ngFor="let entry of entries | async" [value]="entry">
          <div>{{ entry.name }}</div>
          <div style="font-weight: bold">{{ entry.product.name }}</div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</mat-dialog-content>

<dialog-form-actions>
  <button [disabled]="form.invalid || !form.dirty" [mat-dialog-close]="entry" color="primary" mat-raised-button>
    <mat-icon>done</mat-icon>
    Select
  </button>
</dialog-form-actions>

<mat-toolbar style="display:flex;gap: 1em">
  <h4 style="margin:0; flex:1">Enter Product-Details</h4>

  <button mat-stroked-button (click)="addRow()" [disabled]="form.invalid" type="button">
    <mat-icon>add</mat-icon>
    Add Row
  </button>
  <button mat-raised-button [mat-dialog-close]="formData()" [disabled]="form.invalid"
          color="primary"
          type="button">
    <mat-icon>save</mat-icon>
    save
  </button>
  <button mat-raised-button mat-dialog-close type="button">
    <mat-icon>close</mat-icon>
    close
  </button>
</mat-toolbar>

<mat-dialog-content style="padding: 0">
  <form [formGroup]="form">
    <mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Product Specifications</mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <mat-form-field appearance="fill">
            <input matInput formControlName="name" placeholder="">
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hsCode">
        <mat-header-cell *matHeaderCellDef>HS-Code</mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <mat-form-field appearance="fill">
            <input matInput formControlName="hsCode">
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef>Quantity</mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <mat-form-field appearance="fill">
            <input matInput formControlName="quantity" type="number" style="text-align: right">
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="unit">
        <mat-header-cell *matHeaderCellDef>Unit</mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <mat-form-field appearance="fill">
            <mat-select formControlName="unit" placeholder="Select unit">
              <mat-option *ngFor="let unit of units" [value]="unit">{{ unit }}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <button mat-icon-button (click)="deleteRow(i)" type="button">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: ['name','hsCode','quantity','unit','edit']"></mat-row>
      <mat-header-row *matHeaderRowDef="['name','hsCode','quantity','unit','edit']"></mat-header-row>
    </mat-table>
  </form>
</mat-dialog-content>

import {inject, Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {firstValueFrom} from 'rxjs';
import {ComponentType} from '@angular/cdk/overlay';
import {MapSelectDialogComponent} from '../company/manage/map-select-dialog.component';
import {DocumentEditDialogComponent} from '../company/document/document-edit-dialog.component';
import {
  AppId,
  Company,
  Document,
  DocumentType,
  HasId,
  HasIdName,
  Product,
  ProductChain,
  SupplierProduct,
  User
} from '../model';
import {ConfirmDeleteDialogComponent} from './confirm-delete/confirm-delete-dialog.component';
import {UserProfileAcceptTermsDialog} from '../user/user-profile-accept-terms-dialog.component';
import {SelectOwnerDialogComponent} from '../admin/company/select-owner-dialog.component';
import {DocumentTypeService, UserService} from './service';
import {DocumentUploadDialogComponent} from '../admin/document/document-upload-dialog.component';
import {ProductEditDialogComponent} from '../company/site_chain/product-edit-dialog.component';
import {ProductSelectSupplierDialogComponent} from '../company/site_chain/product-select-supplier-dialog.component';
import {ProductAddDialogComponent} from '../company/site_chain/product-add-dialog.component';
import {NewsDialogComponent} from '../company/evaluation/news-dialog.component';
import {ShapeUploadDialogComponent} from '../admin/shape/shape-upload-dialog.component';
import {UploadResult} from './service/location.service';
import {DocumentsDialogComponent} from '../company/evaluation/documents-dialog.component';
import {ExploreLandDialogComponent} from '../company/evaluation/explore-land-dialog.component';
import {DocumentEditDetailDialogComponent, RowData} from "../company/document/document-edit-detail-dialog.component";
import {PDFPreviewDialogComponent} from "../company/evaluation/pdf-preview-dialog.component";


@Injectable({providedIn: 'root'})
export class Dialog {
  private dialog = inject(MatDialog);
  private userService = inject(UserService);
  private documentTypeService = inject(DocumentTypeService);

  open = async <R = any>(component: ComponentType<any>, config?: MatDialogConfig): Promise<R> =>
    await firstValueFrom<R>(this.dialog.open(component, config).afterClosed());

  shapeFile = async (shape: any) => this.open<UploadResult>(
    ShapeUploadDialogComponent,
    {
      disableClose: true,
      width: '100vw', maxWidth: 'calc(100vw - 30px)', height: '100vh', maxHeight: 'calc(100vh - 120px)',
      data: {shape}
    });

  uploadDocument = async (title: string, types: DocumentType[]) => this.open<Document>(
    DocumentUploadDialogComponent,
    {
      autoFocus: true, disableClose: true, width: '100vw', maxWidth: '600px',
      data: {types, title}
    });

  selectLocation = async (lat: number | undefined, lng: number | undefined) =>
    this.open(MapSelectDialogComponent,
      {
        disableClose: true,
        width: '100vw', maxWidth: 'calc(100vw - 30px)', height: '100vh', maxHeight: 'calc(100vh - 120px)',
        data: {lat, lng}
      });

  editDocument = async (document: Document, documentTypes: DocumentType[]) =>
    this.open(DocumentEditDialogComponent,
      {
        disableClose: true, width: '100vw', maxWidth: '600px',
        data: {document, documentTypes}
      });

  editDocumentDetail = async (document: Document) =>
    this.open<RowData[] | undefined>(DocumentEditDetailDialogComponent,
      {
        disableClose: true, width: '100vw', maxWidth: '800px',
        autoFocus: false, data: {document}
      });

  confirmDelete = async (name: string, type: string) =>
    this.open(ConfirmDeleteDialogComponent, {
      disableClose: true, width: '100vw', maxWidth: '600px',
      data: {name, type}
    });

  acceptTerms = async (data: User) =>
    this.open(UserProfileAcceptTermsDialog, {
      autoFocus: true,
      width: '100vw',
      maxWidth: '600px',
      disableClose: true,
      data
    });

  selectOwner = async (company: Company) =>
    this.open<AppId>(SelectOwnerDialogComponent, {
      width: '100vw', maxWidth: '600px',
      data: {company, users: await this.userService.all()}
    });

  showNews = async (risks: HasId[], locations: HasIdName[]) =>
    this.open(NewsDialogComponent, {
      width: '100vw', maxWidth: 'calc(100vw - 30px)',
      height: 'calc(100vh - 50px)',
      data: {risks, locations}
    });

  showExploreLand = async (id: string) =>
    this.open(ExploreLandDialogComponent, {
      width: '100vw', maxWidth: 'calc(100vw - 30px)',
      height: 'calc(100vh - 50px)',
      data: {id}
    });

  editProduct = async (product: Product) =>
    this.open(ProductEditDialogComponent, {
      disableClose: true, width: '100vw', maxWidth: '600px',
      data: {product}
    });

  selectSupplier = async (product: Product, suppliers: SupplierProduct[]) =>
    this.open<SupplierProduct>(ProductSelectSupplierDialogComponent, {
      disableClose: true, width: '100vw', maxWidth: '600px',
      data: {product, suppliers}
    });

  addProduct = async () =>
    this.open<Product>(ProductAddDialogComponent, {
      disableClose: true, width: '100vw', maxWidth: '600px'
    });

  showProductDocuments = async (product: ProductChain) =>
    this.open(DocumentsDialogComponent, {
      width: '100vw', maxWidth: 'calc(50vw - 30px)',
      minHeight: 'calc(50vh - 50px)',
      data: {product}
    });

  showPdf = async (title: string, pdf: Blob) =>
    this.open(PDFPreviewDialogComponent, {
      autoFocus: false, width: '100vw', maxWidth: 'calc(100vw - 30px)',
      height: 'calc(100vh - 50px)',
      data: {pdf, title}
    })
}

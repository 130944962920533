import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MapSourceNamePipe} from './map-source-name.pipe';
import {ChainDocument, ReportDocument} from '../../model';
import {RowData} from "../document/document-edit-detail-dialog.component";

@Component({
  selector: 'app-report-product-documents',
  standalone: true,
  templateUrl: 'report-product-documents.component.html',
  styleUrls: ['report-product-documents.component.scss'],
  imports: [CommonModule, MatIconModule, MapSourceNamePipe]
})
export class ReportProductDocumentsComponent {
  @Input() preview!: { [p: string]: string };
  @Input() documents!: (ReportDocument | ChainDocument)[];

  docDetail() {
    return Object.values(this.documents
      .filter(d => !!d.details)
      .flatMap(d => JSON.parse(d.details!) as RowData[])
      .reduce((acc, {name, hsCode, unit, quantity}) => {
        const key = [name, hsCode, unit].join('-');
        if (!acc[key]) {
          acc[key] = {name, hsCode, unit, quantity: 0};
        }
        acc[key].quantity += quantity;
        return acc;
      }, {} as { [key: string]: RowData }))
  }
}
